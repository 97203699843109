<template>
 <div class="footerBox">
   <div class="lastFooter">
       Copyright {{new Date().getFullYear()}} 贵州名辉企业管理有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank"> 黔ICP备2023011935号-4</a>
     <img style="width: 20px;height: 20px;margin-bottom: 5px;margin-left: 10px;margin-right: 3px;" src="../assets/fav.png" alt="">
<!--     <a href="https://beian.mps.gov.cn/#/query/webSearch?code=52030202001982" rel="noreferrer" target="_blank">贵公网安备52030202001982</a>-->
     <a href="https://beian.mps.gov.cn/#/query/webSearch?code=52030202001988" rel="noreferrer" target="_blank">贵公网安备52030202001988</a>
   </div>
 </div>

</template>

<script>
  import logoImg from "@/assets/logo.png"
  export default {
    name:'Footer',
    data(){
      return {
        logoImg,

      }
    }
  }
</script>

<style lang="scss" scoped>
 .footerBox{
   background: #000;
   .firstWrap{
     height: 299px;
     border:1px solid hsla(0,0%,100%,.25);
     >div{
       height: 100%;
     }
     .firstFooter{
       display: flex;
       justify-content: center;
       align-items: center;
     }
     .footerContent{ //中间内容区域
       color:#fff;
       padding-top: 20px;
       >a{
         font-size: 20px;
         font-weight: 600;
       }
       >div{
        margin: 12px;
       }
     }
     .focusUs{ //关注我们
       color:#fff;
     }
   }
   .lastFooter{
     color: #fff;
     height: 60px;
     line-height: 60px;
     text-align: center;
   }
 }
 a{
   text-decoration: none;
   color: #FFFFFF;
   text-align: center;
 }
 .cor{
   color: white;
   cursor: pointer;
 }
</style>
