<template>
  <div style="background: #FFFFFF">
    <a-row>
      <a-col :span="24" :offset="2"  class="content-center" @click="changeTitle({key:'homePage'})">
        <h1 class="homeTitle">
          贵州名辉企业管理有限公司
        </h1>
      </a-col>
      <a-col :span="24" :offset="2" class="content-left" style="margin-bottom: 20px;">
       <a-menu v-model="current" mode="horizontal" @select="changeTitle">
        <a-menu-item key="homePage">
           <a-icon type="home" />首页
        </a-menu-item>
           <a-menu-item key="aboutUs">
               <a-icon type="solution" />关于我们
           </a-menu-item>
<!--        <a-menu-item key="newsList">-->
<!--          <a-icon type="eye" />产品简介-->
<!--        </a-menu-item>-->


       <a-menu-item key="donateToUs">
        <a-icon type="transaction" />联系我们
       </a-menu-item>

<!--           <a-menu-item key="cooperationCases">-->
<!--               <a-icon type="transaction" />隐私政策-->
<!--           </a-menu-item>-->
      </a-menu>
      </a-col>
      <a-col>
<!--      <div style="width: 80px;height: 100px;position: fixed;right: 100px;top: 10px;">-->
<!--        <img style="width: 80px;height: 80px;" src="@/assets/QRCode_420.png" alt="">-->
<!--        <div style="text-align: center">扫码下载</div>-->
<!--      </div>-->
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name:'Header',
    data(){
      return{
        searchValue:'',//搜索内容
        current: ['homePage'],// homePage首页  newsList 资讯新闻列表
      }
    },
    methods:{
      onSearch(){
        this.$message.success('触发搜索，搜索内容为：'+ this.searchValue);
      },
      changeTitle(item){
        if(item.key != this.$route.name){
          this.$router.push(item.key)
        }
      }
    },
    created(){
      let routeKsy = sessionStorage.getItem('routeKsy')
      if(routeKsy){
        this.current = [routeKsy]
      }
    }
  }
</script>

<style scoped="scoped" lang="scss">
.homeTitle{
  font-weight: 700;
  color: #303133;
  font-size: 2rem;
  margin-top: 30px;
  width: 100%;
}
  .content-center{
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    >div{
      font-size: 22px;
      color: #2c3e50;
    }
    .img-Logo{
      width: 35px;
      height: 35px;
      vertical-align:center;
    }
  }
  //我们隐藏了 antdvue 导航条的底部线条 并且给了新的高度
  .ant-menu-horizontal{
    border: none;
    line-height: 61px;
  }
  .center{
    text-align: center;
  }
</style>
